import React from 'react';
import { Title } from '@mantine/core';

interface PageTitleProps {
  children: React.ReactNode;
}
const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <Title mt={24} order={3}>
      {children}
    </Title>
  );
};

export default PageTitle;
