import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from 'views/System/PageNotFound';
import SurveyLayout from 'views/Layout/SurveyLayout';
import AuthProvider from 'context/AuthProvider';
import AuthView from 'views/Auth/AuthView';
import { LoadingOverlay } from '@mantine/core';
import PreAccount from 'views/Custom/pages/PreAccount';

const HomeView = React.lazy(() => import('views/Home/HomeView'));
const PageTypeNavigator = React.lazy(() => import('views/PageTypeNavigator'));
const NoGoPage = React.lazy(() => import('views/NoGo/NoGoPage'));

const App = () => {
  return (
    <AuthProvider>
      <SurveyLayout>
        <Suspense fallback={<LoadingOverlay visible />}>
          <Routes>
            <Route index element={<HomeView />} />
            <Route path="register" element={<PreAccount />} />
            <Route path="we-are-sorry-no-go" element={<NoGoPage />} />
            <Route path="account/*" element={<AuthView />} />
            <Route path=":slug" element={<PageTypeNavigator />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </SurveyLayout>
    </AuthProvider>
  );
};

export default App;
