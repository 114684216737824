import { Box } from '@mantine/core';
import React from 'react';
import PageText from 'components/Texts/PageText';
import NavigationButtons from 'components/Navigation/NavigationButtons';
import Stepper from 'components/Steps/Stepper';
import { getIllustration } from 'helpers/Survey.utils';
import { useGetSurvey } from 'hooks/useGetSurvey';
import { pageDataSelector } from 'hooks/selectors';
import PageTitle from 'components/Texts/PageTitle';

const PlanRecommendation = () => {
  const { data: pageData } = useGetSurvey({
    select: (data) => pageDataSelector(data, 'plan-recommendation'),
  });
  return (
    <Box>
      {getIllustration(pageData!.question.slug)}
      <PageTitle>{pageData!.texts.title}</PageTitle>
      <PageText pageData={pageData!} />
      <Stepper active={4} />
      <NavigationButtons
        pageData={pageData!}
        to={`/${pageData!.question.nextStep}`}
        nextText={'Continue'}
      />
    </Box>
  );
};

export default PlanRecommendation;
