import { useQuery } from '@tanstack/react-query';
import { getSurveyData } from 'api/survey';
import { SurveyResponseBody, UpdateSurveyRequestBody } from 'types';
import { useLocalStorage } from '@mantine/hooks';
import { SURVEY_STORAGE_KEY } from 'config';

interface UseGetSurveyOptions<T> {
  select?: (data: SurveyResponseBody) => T;
  enabled?: boolean;
}

export const useGetSurvey = <T extends any = SurveyResponseBody>({
  select,
  enabled = true,
}: UseGetSurveyOptions<T> = {}) =>
  useQuery(['survey'], getSurveyData, {
    select,
    staleTime: 1000 * 60 * 10 /* 10 minutes */,
    enabled: typeof enabled === 'undefined' ? true : enabled,
  });

export const useGetMySurvey = () => {
  const [survey] = useLocalStorage<UpdateSurveyRequestBody>({ key: SURVEY_STORAGE_KEY });
  return { data: survey };
};
