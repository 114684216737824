import React, { Suspense } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import PageNotFound from 'views/System/PageNotFound';
import UserProvider from 'context/UserProvider';
import useSetUserData from 'hooks/useSetUserData';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import ChooseYourCoach from 'views/Custom/pages/Coach/ChooseYourCoach';
import { ActionIcon, Group, LoadingOverlay } from '@mantine/core';
import { InlineIcon } from '@iconify/react';
import { useAuth0 } from '@auth0/auth0-react';
import PlanRecommendation from 'views/Custom/pages/PlanRecommendation';
import { isNativeApp } from 'helpers/NativeApp.utils';

const NoGoPage = React.lazy(() => import('views/NoGo/NoGoPage'));
// const TestimonialsPage = React.lazy(
//   () => import('views/Custom/pages/Testimonials/TestimonialsPage'),
// );
const PlanPage = React.lazy(() => import('views/PlanPreview/PlanPage'));
const PaymentPage = React.lazy(() => import('views/Payment/PaymentPage'));
const PaymentResponse = React.lazy(() => import('views/Payment/PaymentResponse'));
const DownloadAppPage = React.lazy(() => import('views/DownloadApp/DownloadAppPage'));
const FinishPage = React.lazy(() => import('views/Finish/FinishPage'));

const AuthenticatedSurvey = () => {
  const { logout, isAuthenticated } = useAuth0();
  const [searchParams] = useSearchParams();
  const enableLogout = searchParams.get('ff_logout') === '1';
  const handleLogout = async () => {
    logout({ returnTo: window.location.origin });
  };
  useSetUserData();
  return (
    <UserProvider>
      {enableLogout && (
        <Group align={'center'} position="apart" mt={4} mb={4}>
          {isAuthenticated && (
            <ActionIcon onClick={handleLogout}>
              <InlineIcon icon={'ri:logout-circle-line'} height={16} color={'#E07A3d'} />
            </ActionIcon>
          )}
        </Group>
      )}
      <Suspense fallback={<LoadingOverlay visible />}>
        <Routes>
          <Route
            index
            element={true ? <ChooseYourCoach /> : <ProtectedRoute component={ChooseYourCoach} />}
          />
          <Route
            path="meet-your-coach"
            element={true ? <ChooseYourCoach /> : <ProtectedRoute component={ChooseYourCoach} />}
          />
          <Route
            path="plan-recommendation"
            element={
              isNativeApp ? (
                <PlanRecommendation />
              ) : (
                <ProtectedRoute component={PlanRecommendation} />
              )
            }
          />
          {/* <Route path="testimonials" element={true ? <TestimonialsPage/> : <ProtectedRoute component={TestimonialsPage} />} /> */}
          <Route
            path="plan-preview"
            element={true ? <PlanPage /> : <ProtectedRoute component={PlanPage} />}
          />
          <Route
            path="checkout"
            element={true ? <PaymentPage /> : <ProtectedRoute component={PaymentPage} />}
          />
          <Route
            path="payment-response"
            element={true ? <PaymentResponse /> : <ProtectedRoute component={PaymentResponse} />}
          />
          <Route
            path="download-app"
            element={true ? <DownloadAppPage /> : <ProtectedRoute component={DownloadAppPage} />}
          />
          <Route
            path="thank-you"
            element={true ? <NoGoPage /> : <ProtectedRoute component={NoGoPage} />}
          />
          <Route
            path="finish"
            element={true ? <FinishPage /> : <ProtectedRoute component={FinishPage} />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </UserProvider>
  );
};

export default AuthenticatedSurvey;
