import api from 'api/client';
import { User } from 'types';

export type FindCoachResponseBody = {
  status: string;
  coach: User;
  channelId: string;
};
export const findCoach = async () => {
  const { coach } = await api.post(`chat/find_coach`).json<FindCoachResponseBody>();
  return coach;
};

export const getCoaches = async () => {
  const res = await api(`chat/list_coaches`).json<{ coaches: User[] }>();
  return res.coaches;
};

export const switchCoach = async (coachId: string) => {
  return api.put('chat/me_switch_coach', { json: { coachId } });
};
