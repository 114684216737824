import React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (viewport: React.RefObject<HTMLDivElement>) => {
  const { pathname } = useLocation();
  React.useLayoutEffect(() => {
    viewport.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname, viewport]);

  return null;
};

export default ScrollToTop;
