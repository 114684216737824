import React, { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingOverlay } from '@mantine/core';

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute = ({ component }: ProtectedRouteProps) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingOverlay visible />,
  });

  return <Component />;
};

export default ProtectedRoute;
