import React from 'react';
import { Container, ScrollArea } from '@mantine/core';
import { useSurveyLayoutStyles } from 'views/Layout/SurveyLayout.styles';
import { useLocation } from 'react-router-dom';
import useScrollToTop from 'hooks/useScrollToTop';

interface SurveyLayoutProps {
  children: React.ReactNode;
}

const SurveyLayout = ({ children }: SurveyLayoutProps) => {
  const { pathname } = useLocation();

  const { classes } = useSurveyLayoutStyles({ pathname });
  const viewport = React.useRef<HTMLDivElement>(null);
  useScrollToTop(viewport);

  return (
    <ScrollArea
      className={classes.layoutContainer}
      viewportRef={viewport}
      scrollbarSize={4}
      offsetScrollbars>
      <Container size={496} px={16}>
        {children}
      </Container>
    </ScrollArea>
  );
};

export default SurveyLayout;
