import { createStyles } from '@mantine/core';

export const useDeviceSelectionStyles = createStyles((theme) => {
  return {
    base: {
      fontSize: 16,
      borderRadius: theme.radius.md,
      color: theme.black,
      background: theme.white,
      textAlign: 'center',
      fontWeight: 400,
      boxShadow: `0 4px 16px 0 rgba(224, 122, 61, 0.12)`,
      transition: 'all 300ms linear',
      ':hover': {
        background: theme.white,
      },
    },
    active: {
      border: `1px solid ${theme.colors.agaveOrange[3]}`,
    },
  };
});
