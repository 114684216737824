import { CSSObject, MantineTheme } from '@mantine/core';
import { MantineThemeOverride } from '@mantine/styles/lib/theme/types';

const fontFamily = '"Brawler", serif;';

export const getSurveyGlobalStyle = (theme: MantineTheme): CSSObject | CSSObject[] => ({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },

  body: {
    background:
      theme.colorScheme === 'dark'
        ? `${theme.colors.dark}`
        : `${theme.other.colors.appLightBackgroundColor}!important`,
    fontFamily,
    overflowY: 'hidden',
  },

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },

  '@media screen and (max-width: 480px)': {
    'input, select, textarea': {
      fontSize: '16px!important',
    },
  },
});

export const surveyTheme: MantineThemeOverride = {
  // Override any other properties from default theme
  fontFamily,
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.25rem',
  },
  headings: {
    fontFamily,
  },
  primaryColor: 'agaveOrange',
  primaryShade: { light: 4, dark: 4 },
  black: '#0d0d0d',
  colors: {
    agaveOrange: [
      '#ffecd9',
      '#f8d1b8',
      '#edb28e',
      '#e79564',
      '#E07A3d',
      '#c65e20',
      '#99430f',
      '#6f3310',
      '#441e06',
      '#1d0700',
    ],
    agaveGreen: [
      '#EBF4F2',
      '#e0eeeb',
      '#adccc4',
      '#8bb2a4',
      '#6b9988',
      '#49a08a',
      '#377e6b',
      '#235347',
      '#204038',
      '#0f2622',
    ],
  },
  other: {
    colors: {
      appLightBackgroundColor: 'rgb(248, 249, 252)',
      agaveGray: '#b0b0b0',
      agaveBlack: '#0d0d0d',
      agaveWhite: '#ffffff',
      agaveYellow: '#e3a217',
      agaveRed: '#a62121',
      agaveBlue: '#102139',
      agaveRed95: '#f2cece',
    },
    elements: {
      appBorder: '1px solid rgba(72, 94, 144, 0.16)',
    },
  },
  loader: 'bars',
};
