import React from 'react';
import { Box, Flex, Stack, Group, Text } from '@mantine/core';
import { useStepsStyles } from 'components/Steps/Stepper.styles';
import { InlineIcon } from '@iconify/react';

interface StepperProps {
  active: number;
}
const Stepper = ({ active }: StepperProps) => {
  const { classes, cx } = useStepsStyles();

  const step1Done = active > 1;
  const step2Done = active > 2;
  const step3Done = active > 3;

  const step1Active = active === 1;
  const step2Active = active === 2;
  const step3Active = active === 3;

  const step1ClassName = step1Active ? classes.active : step1Done ? classes.completed : '';
  const step2ClassName = step2Active ? classes.active : step2Done ? classes.completed : '';
  const step3ClassName = step3Active ? classes.active : step3Done ? classes.completed : '';

  return (
    <Stack spacing={4}>
      <Flex align={'center'} pl={32} pr={32}>
        <Box className={cx(classes.stepCircle, step1ClassName)}>
          {step1Done ? <InlineIcon icon={'ri:check-fill'} height={24} /> : 1}
        </Box>
        <Box className={classes.stepSeparator} />
        <Box className={cx(classes.stepCircle, step2ClassName)}>
          {step2Done ? <InlineIcon icon={'ri:check-fill'} height={24} /> : 2}
        </Box>
        <Box className={classes.stepSeparator} />
        <Box className={cx(classes.stepCircle, step3ClassName)}>
          {step3Done ? <InlineIcon icon={'ri:check-fill'} height={24} /> : 3}
        </Box>
      </Flex>
      <Group align={'center'} position={'apart'}>
        <Text
          color={step1Active ? 'dark' : 'dimmed'}
          weight={step1Active ? 700 : 400}
          className={classes.stepLabel}
          sx={{ width: 98 }}>
          Getting to know you
        </Text>
        <Text
          color={step2Active ? 'dark' : 'dimmed'}
          weight={step2Active ? 700 : 400}
          className={classes.stepLabel}
          sx={{ width: 85 }}>
          Personalizing your care
        </Text>
        <Text
          color={step3Active ? 'dark' : 'dimmed'}
          weight={step3Active ? 700 : 400}
          className={classes.stepLabel}
          sx={{ width: 104 }}>
          Matching you with a coach
        </Text>
      </Group>
    </Stack>
  );
};

export default Stepper;
