import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { STORAGE_ORIGIN_REGISTER_KEY } from 'config';

const PreAccount = () => {
  const { loginWithRedirect } = useAuth0();
  React.useEffect(() => {
    localStorage.setItem(STORAGE_ORIGIN_REGISTER_KEY, 'true');
    loginWithRedirect({ screen_hint: 'signup' }).then();
  }, [loginWithRedirect]);
  return null;
};

export default PreAccount;
