import { UpdateSurveyRequestBody } from 'types';
import { useLocalStorage } from '@mantine/hooks';
import { SURVEY_STORAGE_KEY } from 'config';

const useUpdateSurvey = () => {
  const [utm] = useLocalStorage<UpdateSurveyRequestBody['utm']>({ key: 'utm' });
  const [, setSurvey, resetSurvey] = useLocalStorage<UpdateSurveyRequestBody>({
    key: SURVEY_STORAGE_KEY,
  });
  const mutate = (payload: UpdateSurveyRequestBody) => {
    const survey = { ...payload, utm };
    setSurvey(survey);
  };
  const reset = () => resetSurvey();
  return { mutate, reset };
};
export default useUpdateSurvey;
