import { createStyles } from '@mantine/core';

interface LayoutProps {
  pathname: string;
}
export const useSurveyLayoutStyles = createStyles((theme, { pathname }: LayoutProps) => {
  return {
    layoutContainer: {
      textAlign: 'center',
      height: 'calc(100vh - 80px)',
      '@media (max-width: 375px)': {
        height: pathname === '/finish' ? 'calc(100vh - 20px)' : 'calc(100vh - 120px)',
      },
      '@media (max-width: 480px)': {
        height: pathname === '/finish' ? 'calc(100vh - 20px)' : 'calc(100vh - 80px)',
      },
    },
  };
});
