export type User = {
  tags?: string;
  oneLiner?: string;
  timezone?: string;
  channelId?: string;
  coachId?: string;
  createdAt: string;
  dateOfBirth?: string;
  email: string;
  firstName: string;
  fullName: string;
  gender: string;
  isCoach: boolean;
  lastName: string;
  nickname: string;
  state: string;
  updatedAt: string;
  userId: string;
  avatarUrl?: string;
  calendarUrl?: string;
  introCallUrl?: string;
  description?: string;
};

export type ChatUser = {
  id: string;
  name: string;
  image: string;
  token: string;
};

export type UserData = {
  me: User;
  coach?: User;
};

export enum PlanEnum {
  free,
  paid,
}

export enum OrderEnum {
  asc = 1,
  desc = -1,
}

export type UsersFilter = {
  isCoach?: boolean;
  textSearch?: string | RegExp;
  plan?: PlanEnum;
  coachId?: string;
  sortBy?: string;
  order?: OrderEnum;
};

export type SearchUsersParams = {
  query?: UsersFilter;
  limit?: number;
  page?: number;
};

export type ChatSwitchCoachParams = {
  coachId: string;
  userId: string;
};

export enum Role {
  User = 'user',
  Coach = 'coach',
}

type AnswerVariant = {
  value: string;
  key: string;
  branchTo?: string;
  text?: string;
};

export enum GroupTitle {
  Info = 'Getting to know you',
  Personalize = 'Personalizing your care',
  Coach = 'Matching you with a coach',
  ADHD = 'ADHD questionnaire',
}

export enum QuestionType {
  Progress = 'progress',
  Custom = 'custom',
  SelectOne = 'select_one',
  SelectMany = 'select_many',
  ResponseView = 'response_view',
  Rate = 'rate',
}
type Question = {
  slug: string;
  type: QuestionType;
  groupTitle?: string;
  answerVariants?: AnswerVariant[];
  groupId?: number;
  nextStep: string | null;
  prevStep?: string;
  max?: number;
};

export type Text = {
  title: string;
  text?: string[];
};
export type Survey = {
  firstQuestion: string;
  title: string;
  questions: {
    [key: string]: Question;
  };
};

export type SurveyResponseBody = {
  survey: Survey;
  texts: { [key: string]: Text };
  groups: { [key: string]: GroupTitle };
};

export type UpdateSurveyRequestBody = {
  fromSlug: string;
  data: UserAnswer[];
  utm?: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_adid?: string;
    utm_adgroup?: string;
    utm_term?: string;
    utm_content?: string;
    gclid?: string;
    coupon?: string;
  };
};

export type PageData = {
  question: Question;
  texts: Text;
};

export interface PageProps {
  pageData: PageData;
}

type Answer = {
  key?: string;
  value?: string | number | boolean | Date;
  text?: string;
};

export type UserAnswer = {
  questionSlug: string;
  answers: Answer[];
};

export type SurveyState = {
  coach: User | undefined;
  setCoach: (coach: User | undefined) => void;
  setAnswers: (answers: UserAnswer[]) => void;
  answers: UserAnswer[];
  setAnswer: (questionSlug: string, answer: Answer[]) => void;
};

export type PayingCustomerResponseBody = {
  customer: {
    email: string;
    name: string;
    id: string;
  };
};

export type SubscriptionRequestBody = {
  customer: User['userId'];
  priceId: string;
};

export type SubscriptionResponseBody = {
  subscriptionId: string;
  clientSecret: string;
};

export type CheckoutResponseBody = {
  checkoutUrl: string;
};
