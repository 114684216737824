import { SurveyResponseBody } from 'types';
import { pagesWithGroupProgress } from 'helpers/Survey.utils';

export const slugsSelector = (data: SurveyResponseBody) => {
  return Object.entries(data?.survey.questions).reduce((acc, [slug]) => {
    acc = [...(acc || []), slug];
    return acc;
  }, [] as string[]);
};

export const pageDataSelector = (data: SurveyResponseBody, slug: string) => {
  return {
    question: data.survey.questions[slug],
    texts: data.texts[slug],
  };
};

export const groupsSelector = (data: SurveyResponseBody) => {
  return Object.entries(data?.survey.questions).reduce(
    (acc, [slug, question]) => {
      if (question.groupId && question.type) {
        acc[question.groupId] = [...(acc[question.groupId] || []), slug].filter((slug) =>
          pagesWithGroupProgress.includes(slug),
        );
      }
      return acc;
    },
    {} as { [key: string]: string[] },
  );
};

export const firstPageSelector = (data: SurveyResponseBody) => data?.survey.firstQuestion;
