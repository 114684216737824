import React from 'react';
import { TextInput, Checkbox, Box, Text, Group, NativeSelect, Center } from '@mantine/core';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { PageData } from 'types';
import { useSurveyStore } from 'store/Survey.store';
import PageTitle from 'components/Texts/PageTitle';
import useUpdateSurvey from 'hooks/useUpdateSurvey';
import NavigationButtons from 'components/Navigation/NavigationButtons';
import { days, months, years } from 'helpers/Survey.utils';
import utc from 'dayjs/plugin/utc';
import DeviceSelection from 'views/Custom/pages/NameAndBirth/Device/DeviceSelection';
import { isNativeApp } from 'helpers/NativeApp.utils';
import { STORAGE_ORIGIN_REGISTER_KEY } from 'config';
import useServerUpdateSurvey from 'hooks/useServerUpdateSurvey';

dayjs.extend(utc);

const termsOfService = 'https://www.agavehealth.com/terms-of-service';
const privacyPolicy = 'https://www.agavehealth.com/privacy-policy';

const TocLink = ({ children, link }: { children: React.ReactNode; link: string }) => (
  <Text component={'a'} href={link} target="_blank" rel="noreferrer" color={'agaveOrange'}>
    {children}
  </Text>
);

interface NameAndBirthProps {
  pageData: PageData;
  nextStep: string;
}
const NameAndBirthForm = ({ nextStep, pageData }: NameAndBirthProps) => {
  const navigate = useNavigate();
  const { answers, setAnswer } = useSurveyStore();
  const updateSurveyMutation = useUpdateSurvey();
  const surveyUser = answers.find((a) => a.questionSlug === pageData.question.slug)?.answers;
  const date = surveyUser?.find((a) => a.key === 'dateOfBirth')?.value;
  const dateOfBirth = date
    ? dayjs.utc(date as string).toDate()
    : dayjs().utc().subtract(18, 'year').toDate();

  const form = useForm({
    initialValues: {
      firstName: surveyUser?.find((a) => a.key === 'firstName')?.value,
      lastName: surveyUser?.find((a) => a.key === 'lastName')?.value,
      month: dateOfBirth.getMonth() + 1,
      day: dateOfBirth.getDate(),
      year: dateOfBirth.getFullYear(),
      termsOfService: surveyUser?.find((a) => a.key === 'termsOfService')?.value,
      device: undefined,
    },

    validate: {
      firstName: (value) => {
        if (!value) {
          return 'First name is required';
        }
      },
      lastName: (value) => {
        if (!value) {
          return 'Last name is required';
        }
      },
      month: (value) => {
        if (!value && value !== 0) {
          return 'Birth month is required';
        }
      },
      day: (value) => {
        if (!value && value !== 0) {
          return 'Birth day is required';
        }
      },
      year: (value) => {
        if (!value) {
          return 'Birth year is required';
        }
      },
      termsOfService: (value) => {
        if (!value) {
          return 'Terms of service must be accepted';
        }
      },
    },
  });

  React.useEffect(() => {
    const originRegister = localStorage.getItem(STORAGE_ORIGIN_REGISTER_KEY);
    if (originRegister === 'true') {
      localStorage.removeItem(STORAGE_ORIGIN_REGISTER_KEY);
      document.cookie = `referrer=https://login.agavehealth.com`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <PageTitle>{pageData.texts.title}</PageTitle>
      <form>
        <Box sx={{ textAlign: 'left' }}>
          <TextInput
            mb={24}
            withAsterisk
            label="First name"
            placeholder="Your first name"
            {...form.getInputProps('firstName')}
          />
          <TextInput
            mb={24}
            withAsterisk
            label="Last name"
            placeholder="Your last name"
            {...form.getInputProps('lastName')}
          />
          <Box mb={24}>
            <Group spacing={4}>
              <Text mb={4} size={'sm'}>
                Date of birth
              </Text>
              <Text size={'sm'} color={'red'}>
                *
              </Text>
            </Group>
            <Group spacing={0} grow>
              <NativeSelect
                styles={{
                  input: {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
                placeholder="Month"
                data={months()}
                {...form.getInputProps('month')}
              />
              <NativeSelect
                styles={{
                  input: {
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                  },
                }}
                placeholder="Day"
                data={days()}
                {...form.getInputProps('day')}
              />
              <NativeSelect
                styles={{
                  input: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }}
                placeholder="Year"
                data={years()}
                {...form.getInputProps('year')}
              />
            </Group>
            {!isNativeApp && (
              <Box mt={24} mb={24}>
                <Center>
                  <Text fw={700} fz={16}>
                    Which phone do you use?
                  </Text>
                </Center>
                <Box mt={16}>
                  <DeviceSelection {...form.getInputProps('device')} />
                </Box>
              </Box>
            )}
            <Text size="sm" color="dimmed">
              Agave Health services are for adults ages 18 years or older.
            </Text>
          </Box>
          <Checkbox
            mt="md"
            label={
              <>
                By checking this box I agree to{' '}
                <TocLink link={termsOfService}>terms and conditions</TocLink> and{' '}
                <TocLink link={privacyPolicy}>privacy policy</TocLink>
              </>
            }
            {...form.getInputProps('termsOfService', { type: 'checkbox' })}
          />
          <Text fz={'sm'} color={'red'}>
            {form.getInputProps('termsOfService').error}
          </Text>
        </Box>
        <NavigationButtons
          pageData={pageData}
          hidePrev={true}
          handleNext={form.onSubmit((values) => {
            setAnswer(pageData.question.slug, [
              { key: 'firstName', value: values.firstName },
              { key: 'lastName', value: values.lastName },
              {
                key: 'dateOfBirth',
                value: dayjs
                  .utc(`${values.year}-${values.month}-${values.day}`, 'YYYY-MM-DD')
                  .toISOString(),
              },
              { key: 'termsOfService', value: values.termsOfService },
              { key: 'device', value: values.device },
            ]);
            updateSurveyMutation.mutate({ fromSlug: pageData.question.slug, data: answers });
            navigate(`/${values.device === 'iphone' || isNativeApp ? nextStep : 'ios-only'}`);
          })}
        />
      </form>
    </Box>
  );
};

export default NameAndBirthForm;
