import ky from 'ky';
import { useUserStore } from 'store/userStore';
import { isNativeApp } from 'helpers/NativeApp.utils';
import { API_URL } from 'config';

const api = ky.extend({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = isNativeApp
          ? localStorage.getItem('loginToken')
          : useUserStore.getState().accessToken;
        request.headers.set('Authorization', `Bearer ${accessToken}`);
      },
    ],
  },
});

export default api;
