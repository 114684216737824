import React from 'react';
import { Button, Variants } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useButtonsStyles } from 'components/Buttons/Buttons.styles';

interface NextButtonProps {
  to?: string;
  onNext?: () => void;
  disabled?: boolean;
  text?: string;
  buttonType?: 'button' | 'submit';
  variant?: Variants<'filled' | 'outline' | 'light' | 'white' | 'default' | 'subtle' | 'gradient'>;
  loading?: boolean;
}
const NextButton = ({
  to,
  onNext,
  disabled,
  text,
  buttonType = 'button',
  variant = 'filled',
  loading,
}: NextButtonProps) => {
  const { classes } = useButtonsStyles();
  const navigate = useNavigate();
  const handleNext = () => {
    onNext?.();
    to && navigate(to);
  };
  return (
    <Button
      loading={loading}
      type={buttonType}
      variant={variant}
      className={classes.button}
      disabled={disabled}
      onClick={handleNext}
      color="agaveOrange">
      {text || 'Next'}
    </Button>
  );
};

export default NextButton;
