import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingOverlay } from '@mantine/core';
import AuthenticatedSurvey from 'views/AuthenticatedSurvey';
import { isNativeApp } from 'helpers/NativeApp.utils';
import Signup from 'views/Auth/Signup';

const AuthView = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) {
    return <LoadingOverlay visible />;
  }
  return isNativeApp || isAuthenticated ? <AuthenticatedSurvey /> : <Signup />;
};

export default AuthView;
