import React from 'react';
import { Title } from '@mantine/core';
import { PageProps } from 'types';

const PageText = ({ pageData }: PageProps) => {
  return (
    <>
      {pageData?.texts?.text?.map((t) => (
        <Title key={t} mb={24} mt={24} order={5} weight={400}>
          {t}
        </Title>
      ))}
    </>
  );
};

export default PageText;
