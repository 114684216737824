import React from 'react';
import { Box, Button, Center, Group } from '@mantine/core';
import { InlineIcon } from '@iconify/react';
import { useDeviceSelectionStyles } from 'views/Custom/pages/NameAndBirth/Device/DeviceSelection.styles';

const buttonSize = 'xl';

export type Device = 'iphone' | 'android';
interface DeviceSelectionProps {
  value: Device;
  onChange: (value: Device) => void;
}
const DeviceSelection = ({ value, onChange }: DeviceSelectionProps) => {
  const { classes, cx } = useDeviceSelectionStyles();

  const handleChange = (value: Device) => {
    onChange(value);
  };
  const iphoneActive = value === 'iphone';
  const androidActive = value === 'android';
  return (
    <Group grow>
      <Button
        onClick={() => handleChange('iphone')}
        size={buttonSize}
        className={cx(classes.base, { [classes.active]: iphoneActive })}>
        <Center>
          <InlineIcon icon={'ic:baseline-apple'} height={32} />
          <Box ml={10}>iPhone</Box>
        </Center>
      </Button>
      <Button
        onClick={() => handleChange('android')}
        size={buttonSize}
        className={cx(classes.base, { [classes.active]: androidActive })}>
        <Center>
          <InlineIcon icon={'ant-design:android-filled'} height={32} />
          <Box ml={10}>Android</Box>
        </Center>
      </Button>
    </Group>
  );
};

export default DeviceSelection;
