import api from 'api/client';
import { UserData, UserAnswer } from 'types';

const userEndpoint = `user/me`;
const userByIdEndpoint = `user`;

export const getUserData = async () => {
  return api(userEndpoint).json<UserData>();
};

export interface UpdateUserFormValues {
  nickname?: string;
  avatarUrl?: string;
  calendarUrl?: string;
  description?: string;
}

export const updateUser = async (body: UpdateUserFormValues) => {
  return api.put(userEndpoint, { json: body }).json<UserData>();
};

export type GetUserByIdResponse = {
  user: UserData;
  onboarding?: UserAnswer[];
};
export const getUserById = async (userId: string) => {
  return api(`${userByIdEndpoint}/${userId}`).json<GetUserByIdResponse>();
};
