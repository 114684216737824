import React from 'react';
import useGetCoachesListQuery from 'hooks/useGetCoachesListQuery';
import {
  Box,
  Image,
  Paper,
  Title,
  Text,
  Loader,
  Flex,
  Button,
  Spoiler,
  Badge,
} from '@mantine/core';
import PageTitle from 'components/Texts/PageTitle';
import { Carousel } from '@mantine/carousel';
import { useSwitchCoachMutation } from 'hooks/useSwitchCoach';
import { isNativeApp } from 'helpers/NativeApp.utils';
import { coachVideoUrls } from 'helpers/Survey.utils';
import ReactPlayer from 'react-player/youtube';
import { useMeetYourCoachStyles } from 'views/Custom/pages/Coach/MeetYourCoach.styles';
import useErrorMessage from 'hooks/useErrorMessage';
import useServerUpdateSurvey from 'hooks/useServerUpdateSurvey';
import { useLocalStorage } from '@mantine/hooks';
import { UpdateSurveyRequestBody } from 'types';
import { useGetMySurvey } from 'hooks/useGetSurvey';

const ChooseYourCoach = () => {
  const { classes } = useMeetYourCoachStyles();
  const [utm, setUtm] = useLocalStorage<UpdateSurveyRequestBody['utm']>({ key: 'utm' });
  const { data: coaches, isFetching, error } = useGetCoachesListQuery();
  const switchCoach = useSwitchCoachMutation();
  const survey = useGetMySurvey();
  const updateSurvey = useServerUpdateSurvey();
  const [currentCoachIndex, setCurrentCoachIndex] = React.useState(0);

  React.useEffect(() => {
    if (utm?.utm_source) {
      updateSurvey.mutate({
        fromSlug: 'meet-your-coach',
        data: survey.data.data,
        utm,
      });
      setUtm(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utm?.utm_source]);

  useErrorMessage(error);
  const handleSwitchCoach = (coachId: string) => {
    switchCoach.mutate(
      { coachId },
      {
        onSuccess: () => {
          if (isNativeApp) {
            window?.ReactNativeWebView?.postMessage();
          } else {
            window.location.href = 'https://apps.apple.com/us/app/agave-health/id6444310955';
          }
        },
        onError: (error) => {
          console.error(error);
          window.location.href = 'https://apps.apple.com/us/app/agave-health/id6444310955';
        },
      },
    );
  };

  return (
    <Box mt={32}>
      <PageTitle>Choose you coach</PageTitle>
      {isFetching && <Loader variant={'dots'} size={'sm'} mt={32} />}
      {coaches && (
        <Box mt={32}>
          <Carousel
            withIndicators
            height={'100%'}
            slideGap="md"
            loop
            align="start"
            onSlideChange={(index) => setCurrentCoachIndex(index)}
            breakpoints={[
              { maxWidth: 'md', slideSize: '50%' },
              { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
            ]}>
            {coaches?.map((coach, index) => {
              const fullName = `${coach.firstName} ${coach.lastName}`;
              const nextText = `Choose ${coach.firstName}`;
              const coachVideoUrl = coachVideoUrls[coach?.userId ?? ''];
              const tags = coach?.tags?.split(',').map((tag) => tag.trim());

              return (
                <Carousel.Slide key={coach.coachId}>
                  <Paper
                    key={coach.coachId}
                    shadow="0 -3px 13px 0 rgba(100, 100, 111, 0.2)"
                    p={32}
                    radius="md">
                    <Title order={4} fw={500}>
                      {fullName}
                    </Title>
                    {!coachVideoUrl && (
                      <Image
                        radius="md"
                        src={coach?.avatarUrl}
                        alt={coach?.nickname}
                        height={320}
                        mt={32}
                        p={24}
                      />
                    )}
                    {coachVideoUrl && (
                      <div className={classes.playerWrapper}>
                        <ReactPlayer
                          url={coachVideoUrl}
                          width={'100%'}
                          height={'100%'}
                          playing={currentCoachIndex === index}
                          controls
                        />
                      </div>
                    )}
                    <Box p={24} ta={'left'}>
                      <Box mt={16}>
                        <Text mt={16}>{coach.oneLiner}</Text>
                      </Box>
                      <Box mt={16}>
                        <Title order={4} fw={600}>
                          Specialities and personality
                        </Title>
                        <Box mt={8}>
                          {tags?.map((tag) => (
                            <Badge key={tag} color="agaveGreen" variant="light" mr={8}>
                              {tag}
                            </Badge>
                          ))}
                        </Box>
                      </Box>
                      <Box mt={16}>
                        <Title order={4} fw={600}>
                          Timezone
                        </Title>
                        <Text mt={8}>🌎 {coach.timezone}</Text>
                      </Box>
                      <Box mt={16}>
                        <Title order={4} fw={600}>
                          About
                        </Title>
                        <Spoiler
                          maxHeight={100}
                          showLabel="Show more"
                          hideLabel="Hide"
                          styles={(theme) => ({
                            control: {
                              paddingTop: 8,
                              color: theme.colors.blue[7],
                            },
                          })}>
                          <Text mt={8}>{coach.description}</Text>
                        </Spoiler>
                      </Box>
                    </Box>
                    <Flex mt={8} gap={16} justify={'center'}>
                      <Button
                        fz={14}
                        onClick={() => handleSwitchCoach(coach.userId)}
                        color="agaveOrange">
                        {nextText}
                      </Button>
                    </Flex>
                  </Paper>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Box>
      )}
    </Box>
  );
};

export default ChooseYourCoach;
