import create from 'zustand';
import { Auth0ContextInterface } from '@auth0/auth0-react/src/auth0-context';
import { ChatUser, UserData } from 'types';
import { User } from '@auth0/auth0-react';
import i18next from 'i18next';
import { getUserData } from 'api/user';
import { isNativeApp } from 'helpers/NativeApp.utils';

interface UserState {
  accessToken: string | null;
  userData: UserData | null;
  chatUser: ChatUser | null;
  isFetchingUser: boolean;
  fetchError: string | null;
  updateUserData: (updatedUserData: Partial<UserData['me']>) => void;
  fetchUserData: (
    getAccessToken: Auth0ContextInterface['getAccessTokenSilently'],
    user: User,
  ) => void;
}

export const useUserStore = create<UserState>()((set, getState) => ({
  accessToken: null,
  userData: null,
  chatUser: null,
  isFetchingUser: false,
  fetchError: null,
  updateUserData: (updatedUserData) => {
    const me = getState().userData?.me;
    set({ userData: { ...getState().userData, me: { ...me!, ...updatedUserData } } });
  },
  fetchUserData: async (getAccessToken, user) => {
    set({ isFetchingUser: true });
    const accessToken = isNativeApp ? localStorage.getItem('localToken') : await getAccessToken();
    set({ accessToken });
    const [meRes] = await Promise.allSettled([getUserData()]);

    const isMeFulfilled = meRes.status === 'fulfilled';
    set({
      isFetchingUser: false,
      ...(isMeFulfilled && { userData: meRes.value }),
      ...(!isMeFulfilled && {
        fetchError: i18next.t('core:user-settings-view.user-profile.unable-to-fetch-user'),
      }),
    });
  },
}));
