import React from 'react';
import { Affix, Flex } from '@mantine/core';
import PrevButton from 'components/Buttons/PrevButton';
import NextButton from 'components/Buttons/NextButton';
import { PageData } from 'types';
import { useMediaQuery } from '@mantine/hooks';

interface NavigationButtonsProps {
  pageData: PageData;
  nextDisabled?: boolean;
  handleNext?: () => void;
  to?: string;
  nextText?: string;
  hidePrev?: boolean;
  nextButtonType?: 'button' | 'submit';
}

const NavigationButtons = ({
  pageData,
  nextDisabled,
  handleNext,
  to,
  nextText,
  hidePrev = false,
  nextButtonType,
}: NavigationButtonsProps) => {
  const isMobile = useMediaQuery('(max-width: 480px)');
  return (
    <Affix position={{ bottom: 16 }} zIndex={100} sx={{ width: '100%' }}>
      <Flex gap={16} justify={'center'}>
        {!hidePrev && !isMobile && <PrevButton to={`/${pageData.question.prevStep}`} />}

        <NextButton
          to={to}
          onNext={handleNext}
          disabled={nextDisabled}
          text={nextText}
          buttonType={nextButtonType}
        />
      </Flex>
    </Affix>
  );
};

export default NavigationButtons;
