import { createStyles } from '@mantine/core';

export const useStepsStyles = createStyles((theme) => {
  return {
    stepCircle: {
      width: 40,
      height: 40,
      border: `1px solid ${theme.colors.agaveOrange[4]}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      borderRadius: '50%',
      color: theme.other.colors.agaveGray,
    },
    active: {
      background: theme.colors.agaveOrange[4],
      color: theme.white,
      fontWeight: 700,
    },
    completed: {
      background: theme.colors.agaveGreen[4],
      color: theme.white,
      borderColor: theme.colors.agaveGreen[4],
    },
    stepSeparator: {
      flex: '1 1 0%',
      height: '1px',
      background: theme.other.colors.agaveGray,
    },
    stepLabel: {
      textAlign: 'center',
    },
  };
});
