import { createStyles } from '@mantine/core';

export const useMeetYourCoachStyles = createStyles((theme) => {
  return {
    playerWrapper: {
      position: 'relative',
      marginTop: theme.spacing.xl,
      height: 360,
      '@media (max-width: 480px)': {
        height: 200,
      },
    },
  };
});
