import { GroupTitle, QuestionType, SurveyResponseBody } from 'types';

const surveyData: SurveyResponseBody = {
  survey: {
    title: "Let's get to know you better",
    firstQuestion: 'name-and-birth',
    questions: {
      'progress-basic': {
        type: QuestionType.Progress,
        groupId: 1,
        groupTitle: 'Getting to know you',
        nextStep: 'name-and-birth',
        slug: 'progress-basic',
      },
      'name-and-birth': {
        type: QuestionType.Custom,
        groupId: 0,
        groupTitle: 'Getting to know you',
        nextStep: 'how-you-identify',
        slug: 'name-and-birth',
      },
      'ios-only': {
        type: QuestionType.Custom,
        groupId: 1,
        groupTitle: 'Getting to know you',
        nextStep: 'account/meet-your-coach',
        prevStep: 'name-and-birth',
        slug: 'ios-only',
      },
      'coach-welcome': {
        type: QuestionType.Custom,
        groupId: 0,
        groupTitle: 'Getting to know you',
        nextStep: 'how-you-identify',
        prevStep: 'name-and-birth',
        slug: 'coach-welcome',
      },
      'how-you-identify': {
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Female', key: 'female' },
          { value: 'Male', key: 'male' },
          { value: 'Non-Binary', key: 'non-binary' },
          { value: 'Prefer not to disclose', key: 'prefer-not-to' },
        ],
        groupId: 0,
        groupTitle: 'Getting to know you',
        nextStep: 'your-challenge-is',
        prevStep: 'name-and-birth',
        slug: 'how-you-identify',
      },
      'live-state': {
        type: QuestionType.Custom,
        groupId: 0,
        groupTitle: 'Getting to know you',
        nextStep: 'whats-your-motivation',
        prevStep: 'how-you-identify',
        slug: 'live-state',
      },
      'whats-your-motivation': {
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Learn more about ADHD', key: 'learn-more-about-adhd' },
          { value: 'Better manage my ADHD', key: 'better-manage-adhd' },
          { value: 'Have someone keep me accountable', key: 'someone-keep-me-accountable' },
          { value: 'Other', text: '', key: 'other' },
        ],
        groupId: 0,
        groupTitle: 'Getting to know you',
        nextStep: 'did-you-know-currently-diagnosed',
        prevStep: 'live-state',
        slug: 'whats-your-motivation',
      },
      'did-you-know-currently-diagnosed': {
        type: QuestionType.ResponseView,
        nextStep: 'have-you-experienced-adhd',
        groupId: 0,
        groupTitle: 'Getting to know you',
        prevStep: 'whats-your-motivation',
        slug: 'did-you-know-currently-diagnosed',
      },
      'have-you-experienced-adhd': {
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Yes', key: 'yes', branchTo: 'how-would-you-like-agave' },
          { value: 'No', key: 'no' },
        ],
        groupId: 0,
        groupTitle: 'Getting to know you',
        nextStep: 'process-personalize',
        prevStep: 'did-you-know-currently-diagnosed',
        slug: 'have-you-experienced-adhd',
      },
      'how-would-you-like-agave': {
        type: QuestionType.SelectMany,
        answerVariants: [
          { value: 'Higher frequency', key: 'frequency' },
          { value: 'Receive better support', key: 'better-support' },
          { value: 'More affordable', key: 'affordable' },
          { value: 'More immediate support', key: 'immediate-support' },
          { value: 'Other', text: '', key: 'other' },
        ],
        groupId: 0,
        groupTitle: 'Getting to know you',
        nextStep: 'process-personalize',
        prevStep: 'have-you-experienced-adhd',
        slug: 'how-would-you-like-agave',
      },
      'process-personalize': {
        groupId: 2,
        groupTitle: 'Personalizing your care',
        nextStep: 'discribe-diagnosis',
        type: QuestionType.Progress,
        prevStep: 'how-would-you-like-agave',
        slug: 'process-personalize',
      },
      'discribe-diagnosis': {
        groupId: 2,
        groupTitle: 'Personalizing your care',
        nextStep: 'rate-affect-life',
        type: QuestionType.SelectOne,
        answerVariants: [
          {
            value: 'I was formally diagnosed by a licensed healthcare professional',
            key: 'licenced_prof',
          },
          {
            value: 'I self diagnosed',
            key: 'self',
          },
          {
            value: "I'm not sure I have ADHD...",
            key: 'not-sure-i-have',
          },
        ],
        prevStep: 'process-personalize',
        slug: 'discribe-diagnosis',
      },
      'rate-affect-life': {
        groupId: 2,
        groupTitle: 'Personalizing your care',
        nextStep: 'how-manage-adhd',
        type: QuestionType.Rate,
        prevStep: 'discribe-diagnosis',
        slug: 'rate-affect-life',
      },
      'how-manage-adhd': {
        groupId: 2,
        groupTitle: 'Personalizing your care',
        nextStep: 'did-you-know-cbt',
        type: QuestionType.SelectMany,
        answerVariants: [
          { value: 'I take medication', key: 'medication' },
          { value: 'I see a psychotherapist', key: 'pshychotherapy' },
          { value: 'I see an ADHD coach', key: 'adhd-coach' },
          { value: 'I try to figure it out on my own', key: 'on-my-own' },
          { value: 'Other', text: '', key: 'other' },
        ],
        prevStep: 'rate-affect-life',
        slug: 'how-manage-adhd',
      },
      'did-you-know-cbt': {
        groupId: 2,
        groupTitle: 'Personalizing your care',
        nextStep: 'your-challenge-is',
        type: QuestionType.ResponseView,
        prevStep: 'how-manage-adhd',
        slug: 'did-you-know-cbt',
      },
      'your-challenge-is': {
        groupId: 2,
        groupTitle: 'Personalizing your care',
        nextStep: 'account/meet-your-coach',
        type: QuestionType.SelectMany,
        answerVariants: [
          { value: 'Time management and planning', key: 'tm-planning' },
          { value: 'Focus and attention', key: 'focus-attention' },
          { value: 'Work life balance', key: 'work-life-balance' },
          { value: 'Emotional regulation', key: 'emotional-reg' },
          { value: 'Interpersonal relationships', key: 'intre-relationships' },
          { value: 'Impulsivity', key: 'impulsivity' },
          { value: 'Overall health and wellbeing', key: 'health-wellbeing' },
          { value: 'Other', text: '', key: 'other' },
        ],
        max: 3,
        prevStep: 'how-you-identify',
        slug: 'your-challenge-is',
      },
      'what-apply-to-you': {
        groupId: 2,
        groupTitle: 'Personalizing your care',
        nextStep: 'progress-coach',
        type: QuestionType.SelectMany,
        answerVariants: [
          {
            value: 'Had thoughts of self-harm in the last 6 months',
            key: 'hurting-myself',
            branchTo: 'we-are-sorry-no-go',
          },
          { value: 'Diagnosed with OCD', key: 'ocd' },
          { value: 'Diagnosed with ASD', key: 'asd' },
          { value: 'Experienced clinical anxiety in the last 6 months', key: 'anxiety' },
          { value: 'Experienced depression in the last 6 months', key: 'depression' },
          {
            value: 'Have been self medicating in the last 6 months',
            key: 'self-medicating',
          },
          { value: 'None', key: 'none' },
        ],
        prevStep: 'your-challenge-is',
        slug: 'what-apply-to-you',
      },
      'lets-better-understand-prompt': {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'how-often-trouble-wrapping',
        type: QuestionType.Custom,
        prevStep: 'discribe-diagnosis',
        slug: 'lets-better-understand-prompt',
      },
      'how-often-trouble-wrapping': {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'trouble-order',
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Very often', key: 'very-often' },
          { value: 'Often', key: 'often' },
          { value: 'Sometimes', key: 'sometimes' },
          { value: 'Rarely', key: 'rarely' },
          { value: 'Never', key: 'never' },
        ],
        prevStep: 'lets-better-understand-prompt',
        slug: 'how-often-trouble-wrapping',
      },
      'trouble-order': {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'trouble-appointment',
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Very often', key: 'very-often' },
          { value: 'Often', key: 'often' },
          { value: 'Sometimes', key: 'sometimes' },
          { value: 'Rarely', key: 'rarely' },
          { value: 'Never', key: 'never' },
        ],
        prevStep: 'how-often-trouble-wrapping',
        slug: 'trouble-order',
      },
      'trouble-appointment': {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'trouble-thought',
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Very often', key: 'very-often' },
          { value: 'Often', key: 'often' },
          { value: 'Sometimes', key: 'sometimes' },
          { value: 'Rarely', key: 'rarely' },
          { value: 'Never', key: 'never' },
        ],
        prevStep: 'trouble-order',
        slug: 'trouble-appointment',
      },
      'trouble-thought': {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'fidget',
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Very often', key: 'very-often' },
          { value: 'Often', key: 'often' },
          { value: 'Sometimes', key: 'sometimes' },
          { value: 'Rarely', key: 'rarely' },
          { value: 'Never', key: 'never' },
        ],
        prevStep: 'trouble-appointment',
        slug: 'trouble-thought',
      },
      fidget: {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'overly-active-motor',
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Very often', key: 'very-often' },
          { value: 'Often', key: 'often' },
          { value: 'Sometimes', key: 'sometimes' },
          { value: 'Rarely', key: 'rarely' },
          { value: 'Never', key: 'never' },
        ],
        prevStep: 'trouble-thought',
        slug: 'fidget',
      },
      'overly-active-motor': {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'score-screen',
        type: QuestionType.SelectOne,
        answerVariants: [
          { value: 'Very often', key: 'very-often' },
          { value: 'Often', key: 'often' },
          { value: 'Sometimes', key: 'sometimes' },
          { value: 'Rarely', key: 'rarely' },
          { value: 'Never', key: 'never' },
        ],
        prevStep: 'fidget',
        slug: 'overly-active-motor',
      },
      'score-screen': {
        groupId: 4,
        groupTitle: 'ADHD questionnaire',
        nextStep: 'rate-affect-life',
        type: QuestionType.Custom,
        prevStep: 'overly-active-motor',
        slug: 'score-screen',
      },
      'progress-coach': {
        nextStep: 'what-help-need',
        groupId: 3,
        groupTitle: 'Matching you with a coach',
        type: QuestionType.Progress,
        prevStep: 'what-apply-to-you',
        slug: 'progress-coach',
      },
      'what-help-need': {
        nextStep: 'account/meet-your-coach',
        type: QuestionType.SelectOne,
        groupId: 3,
        groupTitle: 'Matching you with a coach',
        answerVariants: [
          { value: 'Hold me accountable', key: 'accountable' },
          { value: 'Frequent check-ins', key: 'check-ins' },
          { value: 'Emotional support', key: 'emotional-support' },
          { value: 'Help me define goals', key: 'define-goals' },
          { value: 'Explain the ADHD brain', key: 'explain-adhd-brain' },
        ],
        prevStep: 'progress-coach',
        slug: 'what-help-need',
      },
      'pre-account': {
        nextStep: 'name-and-birth',
        groupId: 3,
        groupTitle: 'Matching you with a coach',
        type: QuestionType.Custom,
        prevStep: 'what-help-need',
        slug: 'pre-account',
      },
      account: {
        nextStep: null,
        groupId: 3,
        groupTitle: 'Matching you with a coach',
        type: QuestionType.Custom,
        prevStep: 'your-challenge-is',
        slug: 'account',
      },
      'meet-your-coach': {
        nextStep: 'account/plan-preview',
        groupId: 3,
        groupTitle: 'Matching you with a coach',
        type: QuestionType.Custom,
        prevStep: 'your-challenge-is',
        slug: 'meet-your-coach',
      },
      testimonials: {
        nextStep: 'account/plan-preview',
        groupId: 3,
        groupTitle: 'Matching you with a coach',
        type: QuestionType.Custom,
        prevStep: 'account',
        slug: 'testimonials',
      },
      'plan-recommendation': {
        nextStep: 'account/plan-preview',
        type: QuestionType.Custom,
        prevStep: 'account/meet-your-coach',
        slug: 'plan-recommendation',
      },
      'plan-preview': {
        nextStep: 'account/thank-you-page',
        type: QuestionType.Custom,
        prevStep: 'account/plan-recommendation',
        slug: 'plan-preview',
      },
      'thank-you-page': {
        type: QuestionType.Custom,
        nextStep: null,
        prevStep: 'account/plan-preview',
        slug: 'thank-you-page',
      },
    },
  },
  texts: {
    'name-and-birth': { title: 'First, please tell us a bit about yourself' },
    'ios-only': {
      title: 'We’re sorry. Our app is currently available only on iOS.',
      text: ['We’re doing our best to have an Android app as soon as possible.'],
    },
    'progress-basic': {
      title: 'Let’s start with a few questions',
      text: [
        'The following questions will help us provide you with personalized care.',
        'Your answers will not be shared outside Agave Health.',
      ],
    },
    'coach-welcome': {
      title: 'Welcome to Agave, {{first_name}}!',
      text: [
        "The next questions will help match you with the Agave Coach that's the best suited for your needs.",
      ],
    },
    'how-you-identify': { title: '{{name_and_birth.first_name}}, how do you identify?' },
    'live-state': { title: 'What state do you live in?' },
    'whats-your-motivation': { title: 'What is your motivation for joining Agave?' },
    'did-you-know-currently-diagnosed': {
      title: 'Did you know?',
      text: [
        'Fewer than 20% of adults with ADHD are currently diagnosed and treated.',
        'The fact that you are here seeking care is HUGE!',
      ],
    },
    'have-you-experienced-adhd': { title: 'Have you experienced ADHD coaching in the past?' },
    'how-would-you-like-agave': {
      title: 'How would you like Agave to be different from your previous coaching experiences?',
    },
    'process-personalize': {
      title: "It's all about you",
      text: [
        'Tell us more about the symptoms you are experiencing so we can personalize your care.',
      ],
    },
    'discribe-diagnosis': { title: 'How would you describe your diagnosis?' },
    'rate-affect-life': { title: 'How much do your symptoms affect your life?' },
    'how-manage-adhd': { title: '{{first_name}}, how do you manage your ADHD today?' },
    'did-you-know-cbt': {
      title: 'Did you know?',
      text: [
        'Studies show that Cognitive Behavioral Therapy (CBT) impacts the same brain areas as those affected by ADHD medication.',
      ],
    },
    'your-challenge-is': { title: 'What are your biggest challenges today?' },
    'what-apply-to-you': { title: 'Do any of the following apply to you?' },
    'lets-better-understand-prompt': {
      title: 'Let’s better understand your symptoms.',
      text: [
        'The following questionnaire was developed in conjunction with the World Health Organization (WHO) and is designed to determine whether you demonstrate symptoms similar to those of adults with ADHD.',
      ],
    },
    'how-often-trouble-wrapping': {
      title:
        'How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?',
    },
    'trouble-order': {
      title:
        'How often do you have difficulty getting things in order when you have to do a task that requires organization?',
    },
    'trouble-appointment': {
      title: 'How often do you have problems remembering appointments or obligations?',
    },
    'trouble-thought': {
      title:
        'When you have a task that requires a lot of thought, how often do you avoid or delay getting started?',
    },
    fidget: {
      title:
        'How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?',
    },
    'overly-active-motor': {
      title:
        'How often do you feel overly active and compelled to do things, like you were driven by a motor?',
    },
    'score-screen': {
      title: '',
      text: [
        'However, this is not a diagnosis. If you are concerned about behaviours such as inattention, impulsivity or hyperactivity and want to get a formal diagnosis, you may seek clinical evaluation.',
      ],
    },
    'progress-coach': {
      title: 'Meet your coach',
      text: [
        'Lets get you set up with your ADHD coach. We’ll use your answers to fit you with the perfect coach and program. ',
      ],
    },
    'what-help-need': { title: 'In what way would you like your coach to help you?' },
    'pre-account': {
      title: 'Start getting help from your coach right away.',
      text: ['Only one step left!', 'Continue to create your Agave account.'],
    },
    'meet-your-coach': { title: 'Meet {{user.name}}', text: [''] },
    testimonials: { title: 'What our members say', text: [''] },
    'plan-recommendation': {
      title: 'Finish setting up your account',
      text: ['Based on your answers, we’ll recommend the best plan for you.'],
    },
  },
  groups: {
    info: GroupTitle.Info,
    personalize: GroupTitle.Personalize,
    coach: GroupTitle.Coach,
    adhdq: GroupTitle.ADHD,
  },
};

export default surveyData;
