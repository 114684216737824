import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { SurveyState } from 'types';

export const useSurveyStore = create<SurveyState>()((set, getState) => ({
  coach: undefined,
  setCoach: (coach) => set({ coach }),
  answers: [],
  setAnswers: (answers) => set({ answers }),
  setAnswer: (questionSlug, answer) => {
    const { answers } = getState();
    const existingAnswer = answers.find((a) => a.questionSlug === questionSlug);
    if (existingAnswer) {
      existingAnswer.answers = answer;
    } else {
      answers.push({ questionSlug, answers: answer });
    }
    set({ answers });
  },
}));

if (import.meta.env.DEV) {
  mountStoreDevtool('SurveyStore', useSurveyStore);
}
