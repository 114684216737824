import React from 'react';
import { useGetSurvey } from 'hooks/useGetSurvey';
import { pageDataSelector } from 'hooks/selectors';
import NameAndBirthForm from 'views/Custom/pages/NameAndBirth/NameAndBirthForm';

const Signup = () => {
  const { data: pageData } = useGetSurvey({
    select: (data) => pageDataSelector(data, 'name-and-birth'),
  });
  return <NameAndBirthForm pageData={pageData!} nextStep={'how-you-identify'} />;
};

export default Signup;
