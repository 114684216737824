import { createStyles } from '@mantine/core';

export const useButtonsStyles = createStyles((theme) => {
  return {
    button: {
      width: 240,
      height: 46,
      fontSize: theme.fontSizes.lg,
    },
    base: {
      fontSize: 16,
      borderRadius: theme.radius.md,
      color: theme.black,
      background: theme.colors.agaveGreen[0],
      textAlign: 'center',
      fontWeight: 400,
    },
    selection: {
      height: 72,
      width: '100%',
      padding: '0 4px',
      transition: 'all 300ms linear',
    },
    rating: {
      height: 38,
      width: 42,
      margin: '0 4px',
    },
    active: {
      background: theme.colors.agaveGreen[7],
      color: theme.white,
    },
    mobile: {
      position: 'absolute',
      left: 0,
    },
  };
});
