import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

const useErrorMessage = (error?: unknown) => {
  const { t } = useTranslation('core');
  const errorMessage = error instanceof Error ? error.message : '';
  errorMessage &&
    showNotification({
      title: t('core:error'),
      message: errorMessage,
      color: 'red',
    });
};

export default useErrorMessage;
