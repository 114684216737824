import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserStore } from 'store/userStore';

const useSetUserData = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const fetchUserData = useUserStore((state) => state.fetchUserData);

  React.useEffect(() => {
    fetchUserData(getAccessTokenSilently, user!);
  }, [fetchUserData, getAccessTokenSilently, user]);
};

export default useSetUserData;
