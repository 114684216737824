import '@fontsource/brawler';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {
  MantineProvider,
  Global,
  createEmotionCache,
  ColorSchemeProvider,
  ColorScheme,
} from '@mantine/core';
import { surveyTheme, getSurveyGlobalStyle } from 'styles/theme';
import { useLocalStorage } from '@mantine/hooks';
import './i18n';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import { UpdateSurveyRequestBody } from 'types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

dayjs.extend(isLeapYear);
dayjs.extend(relativeTime);

const emotionCache = createEmotionCache({ key: 'agave' });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 10,
    },
  },
});

const Main = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utm_source = searchParams.get('utm_source');
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_adid = searchParams.get('utm_adid');
  const utm_adgroup = searchParams.get('utm_adgroup');
  const utm_term = searchParams.get('utm_term');
  const utm_content = searchParams.get('utm_content');
  const gclid = searchParams.get('gclid');
  const coupon = searchParams.get('coupon');
  const token = searchParams.get('token');

  React.useEffect(() => {
    if (token) {
      localStorage.setItem('loginToken', token);
    }
  }, [token]);

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
  });

  const [, setUtm] = useLocalStorage<UpdateSurveyRequestBody['utm']>({
    key: 'utm',
  });

  React.useEffect(() => {
    if (utm_campaign || utm_medium || utm_source || utm_adid || coupon) {
      setUtm({
        utm_source: utm_source || '',
        utm_medium: utm_medium || '',
        utm_campaign: utm_campaign || '',
        utm_adid: utm_adid || '',
        utm_adgroup: utm_adgroup || '',
        utm_term: utm_term || '',
        utm_content: utm_content || '',
        gclid: gclid || '',
        coupon: coupon || '',
      });
    }
  }, [
    setUtm,
    utm_campaign,
    utm_adid,
    utm_medium,
    utm_source,
    coupon,
    utm_adgroup,
    utm_term,
    utm_content,
    gclid,
  ]);

  const toggleColorScheme = () =>
    setColorScheme((current) => (current === 'dark' ? 'light' : 'dark'));

  return (
    <React.StrictMode>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <QueryClientProvider client={queryClient}>
          <MantineProvider
            emotionCache={emotionCache}
            theme={{ ...surveyTheme, colorScheme }}
            withNormalizeCSS
            withGlobalStyles>
            <Global styles={(theme) => getSurveyGlobalStyle(theme)} />
            <BrowserRouter>
              {/*<StartOverPrompt />*/}
              <App />
            </BrowserRouter>
          </MantineProvider>
        </QueryClientProvider>
      </ColorSchemeProvider>
    </React.StrictMode>
  );
};
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
