import { SurveyResponseBody, UpdateSurveyRequestBody } from 'types';
import surveyData from 'api/data/survey';
import api from 'api/client';
export const getSurveyData = async () => {
  return surveyData;
  // return api(`onboarding/survey`).json<SurveyResponseBody>();
};

export const updateSurveyData = async (payload: UpdateSurveyRequestBody) => {
  return api.put(`onboarding/survey`, { json: payload }).json<SurveyResponseBody>();
};

export const getMySurvey = async () => {
  return;
  //api(`onboarding/survey/me`).json<UpdateSurveyRequestBody>();
};
