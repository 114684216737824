export const API_URL = import.meta.env.DEV
  ? 'https://staging-api.agavehealth.com' //'http://localhost:4000'
  : 'https://api.agavehealth.com';

export const AUTH0_DOMAIN = import.meta.env.DEV ? 'auth.agavehealth.com' : 'login.agavehealth.com';
export const AUTH0_CLIENT_ID = import.meta.env.DEV
  ? 'hUiiWl5osKKsPZTujxNQrAE94PjZpnUU'
  : 'NtdqSgGsOzPVZSUj071s4Lf2nzMJSXXN';
export const AUTH0_AUDIENCE = import.meta.env.DEV
  ? 'https://gothrive.us.auth0.com/api/v2/'
  : 'https://agavehealth.us.auth0.com/api/v2/';

export const STREAM_IO_API_KEY = import.meta.env.DEV ? 'wj54zhx6ghb2' : 'p7u7beerwt5g';

export const AGAVE_STRIPE_PRICE_ID = import.meta.env.DEV
  ? 'price_1MRbv4FPjwMtmgpRTmqbSoiS'
  : 'price_1MJDOyFPjwMtmgpRdIBC7g68';
export const AGAVE_STRIPE_PK = import.meta.env.DEV
  ? 'pk_test_51LRbcIFPjwMtmgpR1Sivyvo9oq5F6lY7OdNNoH8jyTEifIlwqe9Zzk3WovxGugtopG4qnzBKoi9no8rVg8mCACdy00VQfuTIns'
  : 'pk_live_51LRbcIFPjwMtmgpRll9xGK4v70e5udq6JAvvrohp5Xmxsnhuk6ooOXXzXwWjUqj22SGTYtULwlqSYFuGNsHeSfBb00Wup4ufJg';

export const SURVEY_STORAGE_KEY = 'survey';

export const base_imagekit = 'https://ik.imagekit.io/thrive/onboarding-assets';

export const STORAGE_ORIGIN_REGISTER_KEY = 'origin_register';
