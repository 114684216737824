import React from 'react';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useButtonsStyles } from 'components/Buttons/Buttons.styles';

interface NextButtonProps {
  to: string;
}
const PrevButton = ({ to }: NextButtonProps) => {
  const { classes } = useButtonsStyles();
  return (
    <Button
      component={Link}
      to={to}
      color="agaveOrange"
      variant={'outline'}
      className={classes.button}>
      Go back
    </Button>
  );
};

export default PrevButton;
