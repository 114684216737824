import { QuestionType, User, UserAnswer } from 'types';
import dayjs from 'dayjs';
import { base_imagekit } from 'config';

export const ANSWER_OTHER = 'other';
export const ANSWER_NONE = 'none';

export const usStates = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

const pageIllustrations: Record<string, string> = {
  'ios-only': `${base_imagekit}/ios-only.svg`,
  'progress-basic': `${base_imagekit}/progress-basic.svg`,
  'did-you-know-currently-diagnosed': `${base_imagekit}/did-you-know-currently-diagnosed.svg`,
  'process-personalize': `${base_imagekit}/process-personalize.svg`,
  'did-you-know-coaching': `${base_imagekit}/did-you-know-coaching.svg`,
  'did-you-know-cbt': `${base_imagekit}/did-you-know-cbt.svg`,
  'progress-coach': `${base_imagekit}/progress-coach.svg`,
  'plan-recommendation': `${base_imagekit}/progress-coach.svg`,
};

export const coachVideoUrls: Record<string, string> = {
  // ====================== production =============================================
  '63ceddc0074fbc39986a3d8d': 'https://youtube.com/shorts/sWdCzdDN0nQ?feature=share', // Becca,
  '63ceaf57391841e7e36627d1': 'https://youtube.com/shorts/VMbZvzxEkhk?feature=share', // Danielle
  '63cee93cb495640a8f2c4d02': 'https://youtube.com/shorts/bmx_v9f4eJM?feature=share', // Britt
  '63ceafa085d995b87a7a1f98': 'https://youtube.com/shorts/QIidditi25Y?feature=share', // Vida
  // ====================== staging ================================================
  '63bcd052b9b53591b6f3cdec': 'https://youtube.com/shorts/sWdCzdDN0nQ?feature=share', // Becca
  '63518659a12752ff48fd365d': 'https://youtube.com/shorts/VMbZvzxEkhk?feature=share', // Danielle
  '6360fbba7524431ce94a691c': 'https://youtube.com/shorts/bmx_v9f4eJM?feature=share', // Britt
  '63bc7dd0850f2945c88fd6d8': 'https://youtube.com/shorts/QIidditi25Y?feature=share', // Vida,
};

export const getIllustration = (slug: string) => {
  return pageIllustrations[slug];
};

export const homeText = [
  'Let’s start by creating your account and answering a few questions to personalize your care.',
];

export const getRandomLoadingTime = (min = 500, max = 4000) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const userTemplate = (text: string, name: string = '') => {
  return text
    ?.replace(/{{name_and_birth.first_name}}/g, name)
    ?.replace(/{{first_name}}/g, name)
    ?.replace(/{{user.name}}/g, name);
};

const pagesWithSectionTitle = [QuestionType.SelectOne, QuestionType.SelectMany, QuestionType.Rate];
const customPagesWithTitle = ['name-and-birth', 'live-state'];

export const pagesWithGroupProgress = [
  'name-and-birth',
  'how-you-identify',
  'live-state',
  'whats-your-motivation',
  'have-you-experienced-adhd',
  'how-would-you-like-agave',
  'discribe-diagnosis',
  'rate-affect-life',
  'how-manage-adhd',
  'your-challenge-is',
  'what-apply-to-you',
  'lets-better-understand-prompt',
  'how-often-trouble-wrapping',
  'trouble-order',
  'trouble-appointment',
  'trouble-thought',
  'fidget',
  'overly-active-motor',
  'score-screen',
  'what-help-need',
  'meet-your-coach',
];

export const shouldShowSectionTitle = (type: QuestionType, page: string) => {
  return pagesWithSectionTitle.includes(type) || customPagesWithTitle.includes(page);
};

const scoring: Record<string, string[]> = {
  'how-often-trouble-wrapping': ['sometimes', 'often', 'very-often'],
  'trouble-order': ['sometimes', 'often', 'very-often'],
  'trouble-appointment': ['sometimes', 'often', 'very-often'],
  'trouble-thought': ['often', 'very-often'],
  fidget: ['often', 'very-often'],
  'overly-active-motor': ['often', 'very-often'],
};

const getSurveyScore = (answers: UserAnswer[]) => {
  let score = 0;
  for (const item of answers) {
    if (
      scoring[item.questionSlug] &&
      scoring[item.questionSlug].includes(item.answers[0].value as string)
    ) {
      score += 1;
    }
  }
  return score;
};

export const getSurveyResult = (answers: UserAnswer[]) => {
  const score = getSurveyScore(answers);
  const inTheHighRange = score > 3;
  return {
    scoreText: `You scored ${score}/6`,
    feedback: inTheHighRange ? 'You are in the right place!' : '',
    summary: `Scores in the ${inTheHighRange ? '4-6' : '0-3'} range indicate a ${
      inTheHighRange ? 'high' : 'low'
    } likelihood of adult ADHD.`,
  };
};

export const isNewOnboarding = () => {
  const { hostname } = new URL(window.location.href);
  return (
    !!import.meta.env.VITE_AGAVE_WITH_PAYMENT_FLOW || hostname === 'onboarding.agavehealth.com'
  );
};

export const testimonials = [
  {
    id: '1',
    name: 'Jill B.',
    quote: '“This is probably life changing for me”',
  },
  {
    id: '2',
    name: 'Joel.K.',
    quote:
      '“The fact that my coach reaches out to me and reminds me of the things I need to do is amazing”',
  },
  {
    id: '3',
    name: 'Sarah. L.',
    quote:
      '“My spouse doesn’t get me, my parents don’t get me. Finally I have someone in my corner who gets how my brain works”',
  },
];

export const days = () => {
  return Array.from({ length: 31 }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
};
export const months = () => {
  return Array.from({ length: 12 }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
};
export const years = (back = 100) => {
  const year = dayjs().subtract(18, 'year').get('year');
  return Array.from({ length: back }, (v, i) => `${year - back + i + 1}`).reverse();
};

export const getPersonlizeCalendarUrl = (url: string, me?: User, survey?: UserAnswer[]) => {
  const nameAndBirth = survey?.find((item) => item.questionSlug === 'name-and-birth');
  const firstName = nameAndBirth?.answers.find((item) => item.key === 'firstName')?.value;
  const lastName = nameAndBirth?.answers.find((item) => item.key === 'lastName')?.value;
  return firstName && lastName && me
    ? `${url}?name=${firstName} ${lastName}&email=${me.email}`
    : url;
};
